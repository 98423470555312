import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from "react-router-dom";
import quickplayimg from '../../../assets/homBackground.png';
import Loader from '../../../components/loader';
import { checkIfGameTypeOpen } from '../../../httpcalls/gameThunk';
import { alterGameType } from '../../../store/schemas/gameSlice';
import { loadingReset, setLoadingSlice } from '../../../store/schemas/loadingSlice';
const M_QuickPlay = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
            <div className='side-content'>

         

<div className='block-layout'>
    <div className='hero-block' >
        <div className='block-tagline'>
            <div className='block-vert-divder'>
                <div className='block-vert-line'></div>
            </div>
            <h1>Play Now</h1>
        </div>
        <div className='block-charm'>
            <div className='charm-text'>
                <h4>BUSD <span>10.00</span></h4>
                <h2>Quick <span>Play</span> </h2>
                <p>Join the battle for rewards, fight up to 27 players for 28 days and claim your prize!</p>
            </div>
            <div className='charm-button'>
                <button className='btn btn-primary' onClick={
                    () => {
                        dispatch(setLoadingSlice('Loading Game...'));

                        dispatch(checkIfGameTypeOpen({ gameType: "default" })).then((res) => {
                            dispatch(loadingReset())
                            if (!("error" in res.payload)) {
                                dispatch(alterGameType("default"))
                                navigate('/playing')
                            }
                        })


                    }
                }>Play Now</button>
                <button onClick={
                      () => {
                        localStorage.setItem('agreeStatus', 'false');
                        window.location.reload();
                    }
                }>
                   Disclaimer
                </button>
            </div>
        </div>
    </div>
    <p>
    Click 'Play Now' to enter the battle where you'll fight against up to 27 players for a maximum of 28 days, earning rewards based on your kills which can be claimed on the day you die or the day the last enemy is killed.
    </p>

    <p>
    Earn $9.30 per kill (subject to change) in our exhilarating game, regardless of winning or losing the battle!
    </p>

</div>





            </div>
        </>
    );

}

export default M_QuickPlay;