import React, { useRef, useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import skull from '../../assets/skull.svg';

const Finished = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [targetIndex, setTargetIndex] = useState(null)
  const dashboard = useSelector(state => state.mainapp.adminSlice.dashboard)

  
  return (
    <>
    
    <div className='admin-game-manager-container'>
    <div className='pricing-block admin-game-manager'>
        <div className='admin-geneal-game-info'>
            <div>
                <h5>Finished Lobbies:</h5>
                <h5>{dashboard.length}</h5>
            </div>
        </div>

        <div className='game-table'>
            <div className='topper'>
                <div>
                    <h5>Lobby Id</h5>
                </div>
                <div>
                    <h5>Total Players</h5>
                </div>
                <div>
                    <h5>Ending Day</h5>
                </div>
                <div>
                    <h5>Last Fire Time</h5>
                </div>
            </div>
            {(dashboard.length != 0) ?
               dashboard.map((item, index) => {
                return (
                    <div className='game-item' onClick={() => {setTargetIndex(item.id)}}>
                        <div>
                            <h5>{item.id}</h5>
                        </div>
                        <div>
                            <h5>{item.total_players.length}</h5>
                        </div>
                        <div>
                            <h5>{item.dayProgress - 1}</h5>
                        </div>
                        <div>
                            <h5>{item.time == null ? "Not enough players" : (() => {
                                const a = moment(item.time);
                                const c = a.add(5, 'hours')
                                const b = moment.tz(c.toDate(), moment.tz.guess())
                                return      b.format("h:mm:ss a")
                                
                            })()
                            }</h5>
                        </div>
                    </div>
                )
               })
             :
                ""
            }
            </div>
            
           

      

     </div>

     <div className='pricing-block admin-game-manager'>
        {(targetIndex == null) ?
          <div className='no-game-selected'>
            <h2>No Lobby Selected</h2>
        </div>
            :
                (!dashboard.some(item => item.id == targetIndex) ?
                    <div className='no-game-selected'>
                        <h2>Lobby is not Active</h2>
                    </div>
                :
                    (() => {
                        const item = dashboard.find(item => item.id == targetIndex)
                        return (
                            <div class="selected-lobby">
                             <div className='admin-geneal-game-info'>
                                <div>
                                    <h5>Lobby ID: </h5>
                                    <h5>{item.id}</h5>
                                </div>
                                <div>
                                    <h5>Status: </h5>
                                    <h5>
                                        {(() => {
                                           if(item.time == null){
                                            return "Waiting for players"
                                           }
                                           if(item.lock == false){
                                            const a = moment(item.time);
                                            const c = a.add(5, 'hours')
                                            const b = moment.tz(c.toDate(), moment.tz.guess())
                                            return      "Launch at " + b.format("h:mm:ss a")
                                           }
                                           return "Finished"
                                        })()}
                                    </h5>
                                </div>
                                


                            </div>
                            <div className='lobby-info'>
                               
                                <div className='g-block'>
                                    <div className='timeline'>
                                        <div className='timeline-title'>
                                            <h3>Game Timeline <span>Day {item.dayProgress - 1}</span></h3>


                                            <p className='progress-chip'><span>{
                                                (item.dayProgress - 1 == 0 ) ? 0 :item.totalDeadPlayers
                                                }</span> Total Eleminated</p>

                                        </div>

                                        <div className='progress'>
                                            <div className='progress-bar'>
                                                <p>Day {item.dayProgress - 1}</p>
                                                <progress value={item.dayProgress - 1} max={item.maxDayEnd}>
                                                </progress>
                                                <p>Day {item.maxDayEnd}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='g-block'>
                                    <div className='timeline'>
                                        <div className='timeline-title'>
                                            <h3>Players <span>Total {item.total_players.length}</span></h3>
                                        </div>

                                        <div className='admin-players'>
                                            {(item.total_players.length == 0)?
                                                <h5>No players are present</h5>
                                            :
                                                item.total_players.map(item => {
                                                    return(
                                                    <div className='admin-player-card' >
                                                        <div className='member-text'>
                                                            <div className='m-icons'>
                                                                <img src={"https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=" + item.username}></img>
                                                            </div>
                                                            <div className='m-text'>
                                                            
                                                                <h2>{
                                                                item.username.replace(/#.*/, '')
                                                                    }</h2>
                                                        
                                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    
                                    </div>
                                </div>
                                <div className='g-block'>
                                    <div className='timeline'>
                                        <div className='timeline-title'>
                                            <h3>Team 1 <span>Players {item.userTeam.length}</span></h3>
                                        </div>

                                        <div className='admin-players'>
                                            {console.log(item.userTeam)}
                                            {(item.userTeam.length == 0)?
                                                <h5>No players are present</h5>
                                            :
                                                item.userTeam.map(item => {
                                                    return(
                                                    <div className='admin-player-card-team' >
                                                        <div className='member-text'>
                                                            <div className='m-icons'>
                                                                <img src={"https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=" + item.userName}></img>
                                                            </div>
                                                            <div className='m-text'>
                                                            
                                                                <h2>{
                                                                item.userName.replace(/#.*/, '')
                                                                    }</h2>
                                                                {
                                                                    item.dead ? <h3>Eliminated</h3> : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='member-stats'>
                                                        <div className='member-rewards'>
                                                            <h2>{item.totalBusd.toLocaleString("en-us", {style:"currency", currency:"USD"})}</h2>
                                                            <h3>BUSD</h3>
                                                        </div>
                                                        <div className='member-kills'>
                                                            <img src={skull}></img>
                                                            <h2>{item.totalKills}</h2>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    
                                    </div>
                                </div>
                                <div className='g-block'>
                                    <div className='timeline'>
                                        <div className='timeline-title'>
                                            <h3>Team 2 <span>Players {item.enemyTeam.length}</span></h3>
                                        </div>

                                        <div className='admin-players'>
                                            {(item.enemyTeam.length == 0)?
                                                <h5>No players are present</h5>
                                            :
                                                item.enemyTeam.map(item => {
                                                    return(
                                                    <div className='admin-player-card-team' >
                                                        <div className='member-text'>
                                                            <div className='m-icons'>
                                                                <img src={"https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=" + item.userName}></img>
                                                            </div>
                                                            <div className='m-text'>
                                                            
                                                                <h2>{
                                                                item.userName.replace(/#.*/, '')
                                                                    }</h2>
                                                                {
                                                                    item.dead ? <h3>Eliminated</h3> : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='member-stats'>
                                                        <div className='member-rewards'>
                                                            <h2>{item.totalBusd.toLocaleString("en-us", {style:"currency", currency:"USD"})}</h2>
                                                            <h3>BUSD</h3>
                                                        </div>
                                                        <div className='member-kills'>
                                                            <img src={skull}></img>
                                                            <h2>{item.totalKills}</h2>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })()
                   
                )
            }
      
    </div>

    </div>
    
      
    </>
  );

}

export default Finished;