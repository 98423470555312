import React, { useRef, useEffect, useState } from 'react';
import SideToolBar from '../../../components/sideToolbar';
import Footer from '../../../components/footer';

import { Outlet, useNavigate } from 'react-router-dom';
const ActiveGames = () => {

  return (
    <>
      <Outlet />
      <div className='menu-body vivify fadeIn duration-300 delay-200'>


     

        <h1 className='page-title'>My Games</h1>

        <SideToolBar
          links={

            [
              {
                'name': 'Active Games',
                'id': 'active'
              },
              {
                'name': 'Completed Games',
                'id': 'complete'
              },
              {
                'name': 'All Games',
                'id': 'allgames'
              },


            ]
          }
          defaultLink={'active'}
        />

      </div>

    </>
  );

}

export default ActiveGames;