import { createAsyncThunk } from '@reduxjs/toolkit'
import { createContract } from '../helper-functions/contract'
import { loadingReset, setLoadingSlice, setNavigationRedirect } from '../store/schemas/loadingSlice'
import { alterNotification } from '../store/schemas/notificationSlice'
import { getUser } from './authThunk'


const server = process.env.REACT_APP_DOMAIN


  export const validateUsername = createAsyncThunk(
    'shop/validateUsername',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=shop&path=/validateUsername`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({username:data.username})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(setLoadingSlice('MetaMask Approval...'))

            const contract = await createContract(20, false)   
            if("error" in contract ){
                throw contract
            }
            thunkApi.dispatch(setLoadingSlice('Purchasing Username...'))

            const purchaseUsername = await fetch(`${server}?auth=true&model=shop&path=/purchaseUsername`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({tx:contract.tx})               
            })
            const purchaseResults = await purchaseUsername.json()
            if("error" in purchaseResults ){
                throw purchaseResults
            }
            thunkApi.dispatch(getUser())
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(setNavigationRedirect('/shop'))
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'success', messageTitle: "Username Purchase", message: "Your username purchase was a success." ,visible: true}))
            return purchaseResults
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

