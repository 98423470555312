import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { logout } from '../httpcalls/authThunk';
import { useDispatch, useSelector } from 'react-redux';
const ProfileChip = () => {
    const dispatch = useDispatch()
    const username = useSelector(state => state.auth.userInfo.username)
    const avatar = useSelector(state => state.auth.userInfo.avatar)
    const uid = useSelector(state => state.auth.userInfo.uid)



    return (
        <>

        <div className='fairness-btn'>
            <Link to='/fairness/autoplace'><button className='btn btn-primary'><i className='material-icons-outlined'>verified_user</i><span>Provably Fair</span></button></Link>
        </div>

        <div className='profile-chip-container'>
        <div className='profile-chip-wrapper'>
            <div className='profile-chip'>
                <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + username }></img>
                <div className='profile-chip-info'>
                 
                    <p>{
                         username.replace(/#.*/, '') 
                        }</p>
                         <h3>#{ uid.substring(uid.length - 5, uid.length)}</h3>
                    </div>
                   
            </div>
            
            </div>
                 
            <div className='profile-pop '>
  

                    <button onClick={() => {dispatch(logout())}} className='vivify fadeIn duration-300'><i className='material-icons vivify fadeIn duration-300 delay-100'>logout</i><span className='vivify fadeIn duration-300 delay-200'>Logout</span></button>
                </div>
        </div>
        
           
        </>
    );

}

export default ProfileChip;