import React, { useRef, useEffect, useState } from 'react';

import Footer from '../../components/footer';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDashboard } from '../../httpcalls/adminThunk';
import BackPlate from '../../components/backplate';
import SubNavBar from '../../components/subNavbar';

import '../../css/admin.css'
const Admin = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(true)
  const [secondaryLoad, setSecondaryLoad] = useState(true)

  const dispatch = useDispatch()
  const location = useLocation()
    useEffect(() => {
        if(secondaryLoad == false){
            setSecondaryLoad(true)
        }
        if(window.location.pathname == '/admin_console'){
            dispatch(getDashboard({type:'active'})).then((res) => {
                setLoad(false)
                setSecondaryLoad(false)
            })
        }
        else if(window.location.pathname == '/admin_console/finished'){
            dispatch(getDashboard({type:'finished'})).then((res) => {
                setLoad(false)
                setSecondaryLoad(false)
            })
        }
       
    }, [location])
  return (
    <>
        {(load == false) ?
        <>
    
        <BackPlate backplateType="play" />
        <div className='content-body'>
        <SubNavBar links={
            [{
            'name': 'Active Lobbies',
            'link': '/admin_console'
            },
            {
                'name': 'Finished Lobbies',
                'link': '/admin_console/finished'
                }
            ]
        }
        />

        {(secondaryLoad) ? '' :  <Outlet />}
       


      </div>
      <div  className='lg-footer-container' >
            <Footer />
        </div>

        </>
        
    
        :
        
        ""
        }
    
      
    </>
  );

}

export default Admin;