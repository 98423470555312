import { createSlice } from "@reduxjs/toolkit";
/*

    filterLocation: can be {lat:x, long:y} data, or specify the county as a string. This will be managed on the backend.
    filters: [
        current widgets with there respective active filters
        {
            name:"Property Values",
            activeFilters: [
                {
                    name:"face Value",
                    type:"slider",
                    values: {min:0, max: 500}
                }
            ]
        }
    ]

*/

const initialState = () => ({
    gameProgressSlice:{},
    lobbyBackgroundInfo:{}
}
)


/* {todaysKills: 0, 
totalKills: 0, 
totalDead: 0, 
gameRewardsPerKill:9.3, 
currentDay:1, 
maxDay:27, 
playersRewards:{
    userTeam:[{userName:"name", totalKills:0, dead:false}], 
    enemyTeam:[{userName:"name", totalKills:0, dead:false}]
} */

const gameProgressSlice = createSlice({
    name: 'Game Progress',
    initialState: initialState(),
    reducers: {
        resetGameProgress: state => initialState(),
        alterGameProgress: (state, action) => {
            state.gameProgressSlice = action.payload
        },
        alterLobbyBackgroundInfo: (state, action) => {
            state.lobbyBackgroundInfo = action.payload
        },
    }
})

export const {
    resetGameProgress,
    alterGameProgress,
    alterLobbyBackgroundInfo
 

} = gameProgressSlice.actions

export default gameProgressSlice.reducer