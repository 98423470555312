import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import SubNavBar from '../../subNavbar';
import NameChangeProduct from '../../../assets/products/namechange.png';
import { useDispatch } from 'react-redux';
import { validateUsername } from '../../../httpcalls/storeThunk';
import { alterNotification } from '../../../store/schemas/notificationSlice';
import { createContract } from '../../../helper-functions/contract';
import { loadingReset, setLoadingSlice } from '../../../store/schemas/loadingSlice';
const ShopModal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("")
    const purchaseName = async () => {
        if(username.match(/^[A-z0-9_]+$/) != null && username.length <= 25){
            dispatch(setLoadingSlice('Validating Username...'))

            await dispatch(validateUsername({username:username}))
           

        }
        else{
            dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: "Username Criteria", message: "The username doesn't match format: Characters A-z 0-9 _ and a max length of 25." ,visible: true}))
        }
    }


    return (
        <>

            <div className='product'>
                <div className='product-image'>
                    <img src={NameChangeProduct} alt='product' />
                </div>
                <div className='product-action'>
                
                    <div className='product-interact'>
                    <div className='product-title'>
                        <h2><span>20.00</span> BUSD</h2>
                    </div>
                        <div className='product-input'>
                            <label htmlFor='username'>New Username</label>
                            <input onChange={(e) => {setUsername(e.target.value)}} placeholder='Enter Username' maxLength={"25"} type='text' name='username' id='username' />
                            <p>Maximum of 25 Characters A-z 0-9 _</p>
                        </div>
                    </div>
                    <div className='product-checkout vivify fadeIn duration-300 delay-600'>
                        <button onClick={() => { purchaseName()}} className='massive-button mini-btn'> <i className='material-icons'>
                        shopping_cart_checkout
                        </i>
                            CONFIRM & PAY
                        </button>
                        {    /*  NOTICE TO DAMION: please use notification system for when name change is complete  */}
                        <p>By Clicking "Confirm & Pay" You Agree To Our <button className='' onClick={
                            () => {
                                localStorage.setItem('agreeStatus', 'false');
                                window.location.reload();
                            }
                        }>Disclaimer</button></p>
                    </div>
                   
                </div>
            </div>

            <style>
                {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}
        `}
            </style>

        </>
    );

}

export default ShopModal;