import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNavigationRedirect } from '../store/schemas/loadingSlice';

const NavigateFromThunk = () => {
    const navigate = useNavigate()
    const navi = useSelector(state => state.mainapp.loadingSlice.navigation)
    const dispatch = useDispatch()
    useEffect(() => {
        if(navi != null){
            navigate(navi, {replace:true})
            dispatch(setNavigationRedirect(null))
        }
    }, [navi])

  return (
    <>
      
   

    </>
  );

}

export default NavigateFromThunk;