import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import gameSlice from './schemas/gameSlice';
import notificationSlice from './schemas/notificationSlice';
import gameProgressSlice from './schemas/gameProgressSlice';
import adminSlice from './schemas/adminSlice';
import miniMapSlice from './schemas/miniMapSlice';
import loadingSlice from './schemas/loadingSlice';


export const store = configureStore({
  reducer: {
    auth:authSlice,
    mainapp: combineReducers({
      gameSlice: gameSlice,
      notificationSlice: notificationSlice,
      gameProgressSlice: gameProgressSlice,
      miniMapSlice: miniMapSlice,
      adminSlice: adminSlice,
      loadingSlice: loadingSlice

    })
  },
});
