import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const Loader = (prop) => {
  const loadingText = useSelector(state => state.mainapp.loadingSlice.loading)
   
  return (
    <>
      {(loadingText != null) ? 
        <div style={{zIndex:999999999999999}} className='loader-container'>
        <div className='loader vivify swoopInBottom delay-100'>
            <div className='loader-inner '>
            <img src='https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif'></img>
                <h3 className='vivify'>{loadingText}</h3>

                </div>
            </div>

           
            <div className='loader-logo-container'>
            <div className='loader-logo'>
                <h3>0xBullsEye</h3>
                <p>.COM</p>
            </div>
            </div>
          
      </div>
      
      : ""}
    

      

    </>
  );

}

export default Loader;