import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    dashboard: {}
})


const adminSlice = createSlice({
    name:'adminSlice',
    initialState:initialState(),
    reducers: {
        resetAdmin: state => initialState(),
        setDashboard:(state, action)=> {
          state.dashboard = action.payload
        }
    }
})

export const {resetAdmin, setDashboard} = adminSlice.actions

export default adminSlice.reducer