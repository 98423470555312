import React, {useRef, useEffect, useState} from 'react';
import SideToolBar from '../../../components/sideToolbar';
import Footer from '../../../components/footer';
const PlayMenu = () => {

  return (
    <>
   
     <div className='menu-body vivify fadeIn duration-300 delay-200'>
    
     <h1 className='page-title'>PLAY NOW</h1>
     
        <SideToolBar 
        links={
        [{
          'name': 'Quick Play',
          'id': 'quickplay'
        },
        {
          'name': 'How It Works',
          'id': 'howitworks'
        },
        {
          'name': 'Tips & Tricks',
          'id': 'tipsandtricks'
        },
        {
          'name': 'How Much Can I Get',
          'id': 'howmuch'
        },
        ]
      }
      defaultLink={'quickplay'}
      />


     </div>
   
    </>
  );

}

export default PlayMenu;