import React, { useRef, useEffect, useState } from 'react';
import BackPlate from "../../components/backplate";
import SubNavBar from '../../components/subNavbar';
import Footer from '../../components/footer';
import ProfileChip from '../../components/profileChip';
import "../../css/gamePlay.css"
import Sheet from './sheet';
import { useDispatch } from 'react-redux';
import { alterNotification } from '../../store/schemas/notificationSlice';

import { Outlet, useNavigate } from 'react-router-dom';
const GameController = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentSheet, setCurrentSheet] = useState(null);


  useEffect(() => {
   if(window.location.pathname === "/playing/defend"){
     setCurrentSheet("defend");
     console.log("defend");
    
     
    }else if(window.location.pathname === "/playing/attack"){
      setCurrentSheet("attack");
      console.log("attack");
    
    }else if(window.location.pathname === "/playing/matchmaking"){
      setCurrentSheet("matchmaking");
      console.log("matchmaking");
    }

  }, [window.location.pathname]);


  return (
    <>

      <div className='game-container'>
        <div className='game-body'>
          <div className='gamebar-container'>
            <div className='left-gamebar'>
              <div className='exit-btn'>
                <button className='btn btn-primary' onClick={
                  () => {
                    navigate('/play')
                    dispatch(alterNotification( {visible: false} )) ;
                  }
                }><i className='material-icons'>close</i><span>Exit Game</span></button>
              </div>
              {
                currentSheet === "matchmaking" ?
                <>
                <div className='back-btn'>
                <button className='btn btn-primary' onClick={
                  () => {
                    navigate(-1)
                    dispatch(alterNotification( {visible: false} )) ;
                  }
                }><i className='material-icons'>chevron_left</i><span>Back To Selection</span></button>
                </div>
</>: null
              }
            

            </div>

            <div className='right-gamebar'>

              <ProfileChip />

            </div>
          </div>

          <Outlet />
        </div>
        <div id='play-zone' className='play-zone'>
          <div className={
            currentSheet === "defend" ? "visible-flex" : "hidden"
          }>
            <Sheet type="defend" />
          </div>
          <div className={
            currentSheet === "attack" ? "visible-flex vivify flipIn" : "hidden"
          }>
            <Sheet type="attack" />
          </div>


        </div>
      </div>

      <style>
        {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}
        `}
      </style>

    </>
  );

}

export default GameController;