import { createAsyncThunk } from '@reduxjs/toolkit'
import { alterGameProgress } from '../store/schemas/gameProgressSlice'
import { alterNotification } from '../store/schemas/notificationSlice'

const server = process.env.REACT_APP_DOMAIN



  export const checkIfGameTypeOpen = createAsyncThunk(
    'auth/checkIfGameTypeOpen',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=game&path=/checkIfGameIsOpen`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({gameType:data.gameType})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  

  export const validateUserInput = createAsyncThunk(
    'auth/validateUserInput',
    async (data, thunkApi) => {
        try {
            const store = thunkApi.getState()
            const store_data = store.mainapp.gameSlice
            const response = await fetch(`${server}?auth=true&model=game&path=/validateUserInput`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({gameType:store_data.gameType, gamePlan: store_data.gameManager})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            console.log(error)
            return error
        }
    }
  )

  

  export const getUsersLobbiesInfo = createAsyncThunk(
    'auth/getUsersLobbiesInfo',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=game&path=/getUsersLobbiesInfo`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return {message:[]}
        }
    }
  )

  export const getGameProgress = createAsyncThunk(
    'auth/getGameProgress',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=game&path=/getGameProgress`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({gameIndex:data.gameIndex})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(alterGameProgress(results.message))
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )