import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import M_QuickPlay from '../mainapp/play/play/m_quickplay';
import M_HowItWorks from '../mainapp/play/play/m_howitworks';
import M_Tips from '../mainapp/play/play/m_tips';
import M_AllGames from '../mainapp/play/activeGames/m_allgames';
import M_Ongoing from '../mainapp/play/activeGames/m_ongoing';
import M_Featured from '../mainapp/shop/shop/m_featured';
import M_HowMuch from '../mainapp/play/play/m_howmuch';

const SideToolBar = (props) => {


    const links = props.links;
    const [activeLink, setActiveLink] = useState(props.defaultLink);

    useEffect(() => {
       
        /*    console.log(links[0].name);
           console.log(JSON.parse(links)) */
        ;
    }, [])

    return (
        <>
            <div className='side-toolbar-content'>
            <div className='side-buttons'>
            {links.map((link) => (
                <div className='side-button'>
                    <input type="radio" id={link.id} onChange={
                        (e) => {
                            setActiveLink(e.target.value);
                        }
                    } defaultChecked={
                        link.id === props.defaultLink ? true : false
                    } name="sideToolBar" value={link.id} />
                    <label for={link.id}>{link.name}</label>
                </div>
            ))}
            </div>

            
                    
                    {
                        {
                            "quickplay": <M_QuickPlay />,
                            "howitworks": <M_HowItWorks />,
                            "tipsandtricks": <M_Tips />,
                            "allgames": <M_AllGames type={activeLink} />,
                            "active": <M_AllGames type={activeLink} />,
                            "complete": <M_AllGames type={activeLink} />,
                            "featured": <M_Featured />,
                            "howmuch": <M_HowMuch />

                        }[activeLink]
                        
                        
                    }
             

           
            </div>
            
          



        </>
    );

}

export default SideToolBar;