import React, { useRef, useEffect, useState } from 'react';
import "../css/entry.css";
import Logo from "../assets/logo.svg";
import BackPlate from "../components/backplate";
import Login from '../auth/login';
import Footer from '../components/footer';
const MainEntry = () => {

  return (
    <>




      <BackPlate backplateType="entry" />
      <div className='top-landing'>
        <nav className='entry-nav vivify fadeIn duration-300 delay-200'>
          <div className='entry-nav-logo'>
            <img src={Logo} alt='logo' />
          </div>

          <ul className='entry-nav-links' style={
            {
              'display': 'none',
            }
          }>
            <li>
              <a href='#'>Home</a>
            </li>
            <li>
              <a href='#'>How It Works</a>
            </li>
            <li>
              <a href='#'>FAQ</a>
            </li>
            <li>
              <a href='#'>Support</a>
            </li>
          </ul>

        </nav>



        <div className='landing-title vivify fadeIn duration-300 delay-500'>
          <div className='l-title-text'>
            <h1>The Next Generation Of <span>Web 3</span> Gaming</h1>
            <p>Play and Win Real <span>Crypto</span>  Rewards </p>
          </div>
          <div className='l-title-buttons'>
            <Login />
          </div>
        </div>





        <div className='landing-footer'>
          <Footer />
        </div>

       
   
      </div>







    </>
  );

}

export default MainEntry;