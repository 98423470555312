import { createAsyncThunk } from '@reduxjs/toolkit'
import { setDashboard } from '../store/schemas/adminSlice'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { alterNotification } from '../store/schemas/notificationSlice'

const server = process.env.REACT_APP_DOMAIN
export const getDashboard = createAsyncThunk(
    'admin/getDashboard',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/getDashboard`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({type:data.type})
            })
            const results = await response.json()
            if("error" in results){
                window.location.href = '/'
            }

            thunkApi.dispatch(setDashboard(results.message))
            return true
        } catch (error) {
            console.log(error)
        }
    }
  )


  export const addLobby = createAsyncThunk(
    'auth/addLobby',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/addLobby`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})
                
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(getDashboard({type:'active'}))
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return false
        }
    }
  )

  
  export const addFake = createAsyncThunk(
    'auth/addFake',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/addFake`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({lobby_id:data.id})
                
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(getDashboard({type:'active'}))
            return true
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return false
        }
    }
  )
  
  export const shootGame = createAsyncThunk(
    'auth/shootGame',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/shootGame`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({lobby_id:data.id})
                
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            setTimeout(() => {
                thunkApi.dispatch(getDashboard({type:'active'}))
            }, 10000)
            
            return true
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return false
        }
    }
  )