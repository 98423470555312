import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { alterNotification } from '../store/schemas/notificationSlice';
import { current } from '@reduxjs/toolkit';
const ProgressMap = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const currentGame = useSelector(state => state.mainapp.gameSlice.gameManager);
    const pastPlays = useSelector(state => state.mainapp.gameProgressSlice.gameProgressSlice.pastPlays)


    const [itemIndex, setItemIndex] = useState(0);
    const dispatch = useDispatch();



    useEffect(() => {

        console.log(pastPlays[0].results)

        if (collapsed === true) {

            // duplicate checker

            let duplicate = JSON.parse(JSON.stringify(currentGame));

            let frequency = [];



            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {

                document.getElementById("miniA" + num).innerHTML = "";

                document.getElementById("miniB" + num).innerHTML = "";

                document.getElementById("miniC" + num).innerHTML = "";

                document.getElementById("miniA" + num).title = "";

                document.getElementById("miniB" + num).title = "";

                document.getElementById("miniC" + num).title = "";



                /*    if (document.getElementById("miniA" + num).innerHTML === "1") {
                       document.getElementById("miniA" + num).classList.remove("selected-mini");
                   }
                   if (document.getElementById("miniB" + num).innerHTML === "1") {
                       document.getElementById("miniB" + num).classList.remove("selected-mini");
                   } if (document.getElementById("miniC" + num).innerHTML === "1") {
                       document.getElementById("miniC" + num).classList.remove("selected-mini");
                   } */
            })


            pastPlays[0].results.map((item, index) => {

                if (item.attack !== null) {

                    if (frequency.includes(item.attack)) {


                        document.getElementById("mini" + item.attack).innerHTML = document.getElementById("mini" + item.attack).innerHTML + ", " + (parseInt(index) + 1);

                        document.getElementById("mini" + item.attack).title = item.username;

                        document.getElementById("duplicate-detected").style.display = "block";

                    }
                    else {

                        frequency.push(item.attack);


                        document.getElementById("mini" + item.attack).innerHTML = item.username;

                        document.getElementById("mini" + item.attack).title = item.username;

                    }

                }

            }
            )
        }

    }, [currentGame, collapsed])

    const refreshAnimation = () => {
        document.getElementById('miniMap').style.display = 'none';
        setTimeout(() => {
            document.getElementById('miniMap').style.display = 'flex'
        }, 1)
    }



    return (
        <>

            <div className='minimap-container ' >
                <div className='minimap-wrapper ' >

                    {
                        collapsed ? <div className='minimap ' id='miniMap'>
                            <div className='minimap-column-a mini-column vivify fadeIn duration-500 delay-100'>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                        return (
                                            <>
                                                <p title={"Empty"} id={"miniA" + num}>0</p>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className='minimap-column-b mini-column vivify fadeIn duration-500 delay-500'>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                        return (
                                            <>
                                                <p title={"Empty"} id={"miniB" + num}>0</p>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className='minimap-column-c mini-column vivify fadeIn duration-500 delay-1000'>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                        return (
                                            <>
                                                <p title={"Empty"} id={"miniC" + num}>0</p>
                                            </>
                                        )
                                    })
                                }
                            </div>


                        </div>
                            :
                            ""
                    }

                    <p id='duplicate-detected' className='vivify blink delay-100'>Hover over items on mini-map to view all duplicate points.</p>
                </div>
            </div>

            <style>
                {`
        .minimap-container {
            padding-right: 0px !important;
            margin-left: 0px !important;
            width: 320px
        }

        .minimap-wrapper {
            border: none;
        }

        .min-column {
            height: 40px
        }
        `}
            </style>
        </>
    );

}

export default ProgressMap;