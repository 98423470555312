import React, { useRef, useEffect, useState } from 'react';
import Logo from '../assets/logo.svg';
import { Link } from "react-router-dom";
import ProfileChip from './profileChip';
import navbaracrylic from '../assets/navbaracrylic.png';
import { useDispatch, useSelector } from 'react-redux';
import { alterNotification } from '../store/schemas/notificationSlice';

const Navbar = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.auth.userInfo)

   

    return (
        <>

            <nav className='navbar  vivify fadeIn duration-300'>

                <div className='left-nav'>
                <div className='navbar-logo vivify fadeIn duration-300 delay-200'>
                    <img src={Logo} alt='logo' />
                </div>

                <ul className='navbar-links'>

                    <Link to={"/play"}>
                        <button   className='navbar-button vivify fadeIn duration-300 delay-300'  >
                            <i style={
                                { marginLeft: '-8px',
                                 
                                    fontSize: '1.7rem',
                                    marginTop: '-3px',
                                    marginBottom: '-3px',
                                    marginRight: '-5px'}
                            } class="material-icons-outlined">play_arrow</i>
                            <span>Play</span>
                        </button>
                    </Link>

                    <Link to="/shop">
                        <button 
                          className='navbar-button green-outline vivify fadeIn duration-300 delay-350' >
                            <i  class="material-icons-outlined">shopping_cart</i>
                            <span>Shop</span>
                        </button>
                    </Link>

                    <Link >
                        <button onClick={
                            () => {
                                dispatch(alterNotification( {alertStyle: "info",  messageTitle: 'Feature Unavailable', message: 'This feature is currently unavailable. ', visible: true} )) ;
                            }
                        } className='navbar-button yellow-outline vivify fadeIn duration-300 delay-400 disabled-nav' >
                            <i  class="material-icons-outlined">leaderboard</i>
                            <span>Leaderboard</span>
                        </button>
                    </Link>

                    <Link >
                        <button  onClick={
                            () => {
                                dispatch(alterNotification( {alertStyle: "info",  messageTitle: 'Feature Unavailable', message: 'This feature is currently unavailable. ', visible: true} )) ;
                            }
                        } className='navbar-button blue-outline vivify fadeIn duration-300 delay-450 disabled-nav' style={
                            {
                                height: '40px'
                            }
                        } >
                            <i style={
                                {
                                  marginLeft: '-3px', 
                                fontSize: '25px' }   
                            } class="material-icons-outlined">settings_suggest</i>
                            <span>Automate</span>
                        </button>
                    </Link>



                </ul>
                </div>
               


                <div className='nav-profile vivify fadeIn duration-300 delay-500'>
                    <ProfileChip />
                </div>




            </nav>

        </>
    );

}

export default Navbar;