import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import './vivify.min.css'
import './App.css';
import './css/main.css'
import { getUser, logout } from './httpcalls/authThunk';
import Homepage from './mainapp/play/play';
import Shop from './mainapp/shop/shop';
import MainDirectory from './mainapp/mainDirectory';
import MainEntry from './entry/mainentry';
import PlayMenu from './mainapp/play/play/playMenu';
import ShopMenu from './mainapp/shop/shop/shopMenu';
import ActiveGames from './mainapp/play/activeGames/myGamesMenu';
import GameController from './mainapp/game/gameController';
import Defend from './mainapp/game/defend/defendMenu';
import Attack from './mainapp/game/attack/attackMenu';
import Matchmaking from './mainapp/game/matchMaking';
import ModalController from './components/modals/modalController';
import FairModal from './components/modals/fairnessModal/fairController';
import AutoPlace from './components/modals/fairnessModal/autoplace';
import Order from './components/modals/fairnessModal/order';
import GameActivity from './components/modals/gameModal/gameActivity';
import Report from './components/modals/gameModal/report';
import Admin from './mainapp/admin/admin';
import Manager from './mainapp/admin/manager';
import Finished from './mainapp/admin/finished';


const App = () => {
  const dispatch = useDispatch()

  const [appLoad, setAppLoad] = useState(true)
  const appLoadRef = useRef();  // will be same object each render

  const [legalAgree, setLegalAgree] = useState(localStorage.getItem('agreeStatus') == 'true' ? true : false)
  const login = useSelector(state => state.auth.userActive)

  // Remove this line when you're ready to implement the login page
  const [auth, setAuth] = useState(false)
  const uid = useSelector(state => state.auth.userInfo.uid)
  const uidRef = useRef();

  useEffect(() => {
    console.log(legalAgree)
  }, [legalAgree])


  const logoutUser = (accounts) => {

    if (appLoadRef.current == false) {
      if (uidRef.current != null) {
        let f = (accounts == null ? [] : accounts)
        if (f.length == 0) {
          dispatch(logout())
        }
        else {
          if (f[0] != uidRef.current.toLowerCase()) {
            dispatch(logout())
          }
        }
      }
    }
  }

  useEffect(() => {
    dispatch(getUser()).then(res => {
      if (typeof window.ethereum == "undefined") {
        const int = setInterval(() => {
          if (typeof window.ethereum != "undefined") {
            if (window.ethereum._state.accounts != null) {
              clearInterval(int)
              clearTimeout(metamaskNotload)

              window.ethereum.on('accountsChanged', logoutUser);
              setAppLoad(false)
            }
          }
        }, 15)
        const metamaskNotload = setTimeout(() => {
          clearInterval(int)
          clearTimeout(metamaskNotload)
          setAppLoad(false)
        }, 100)
      }
      else {
        if (window.ethereum._state.accounts == null) {
          const int = setInterval(() => {
            if (window.ethereum._state.accounts != null) {
              clearInterval(int)
              clearTimeout(metamaskNotload)
              window.ethereum.on('accountsChanged', logoutUser);
              setAppLoad(false)
            }
          }, 15)
          const metamaskNotload = setTimeout(() => {
            clearInterval(int)
            clearTimeout(metamaskNotload)
            setAppLoad(false)
          }, 100)
        }
        else {
          window.ethereum.on('accountsChanged', logoutUser);
          setAppLoad(false)
        }

      }
    })
  }, [])

  useEffect(() => {
    appLoadRef.current = appLoad;

    if (appLoad == false) {
      if (typeof window.ethereum != 'undefined') {
        logoutUser(window.ethereum._state.accounts)

      }
    }


  }, [appLoad])
  /* 
    useEffect(() => {
      if(legalAgree == false){
        Navigate('/legal')
      }
    }, [legalAgree]) */

  useEffect(() => {
    uidRef.current = uid
  }, [uid])

  return (


    <>
      {(appLoad) ?
        ""
        :
        <Routes>
          {(login || auth) ?

            (legalAgree === true) ?
              <>
                <Route path='/' element={<MainDirectory />} >

                  <Route path='/' element={<Homepage />} >
                    <Route path='/play' element={<PlayMenu />} />
                    <Route path='/games' element={<ActiveGames />}>
                      <Route path='/games/progress' element={<ModalController exit="/games" type="game" heroTitle="Progress" title="Progress" close="true" />} >
                        <Route path='/games/progress/activity' element={<GameActivity />} />
                        <Route path='/games/progress/report' element={<Report />} />

                      </Route>


                    </Route>
                    <Route path='/' element={<Navigate to="/play" replace />} />

                    <Route path='*' element={<Navigate to="/play" replace />} />
                  </Route>

                  <Route path='/shop' element={<Shop />} >
                  <Route path='/shop' element={<ShopMenu />} />
                  <Route path='/shop/item/nameChange' element={<ModalController exit="/shop" type="shop" heroTitle="Shop" title="Change Username" close="true" />} />

                  </Route>
                  <Route path='/playing' element={<GameController />} >
                    <Route path='/playing/defend' element={<Defend />} />
                    <Route path='/playing/attack' element={<Attack />} />
                    <Route path='/playing/matchmaking' element={<Matchmaking />} />
                    <Route path='/playing' element={<Navigate to="/playing/defend" replace />} />
                  </Route>
                  <Route path='/admin_console' element={<Admin />} >
                    <Route path='/admin_console' element={<Manager />} />
                    <Route path='/admin_console/finished' element={<Finished />} />
                  </Route>


                  <Route path='/fairness' element={<ModalController type="fair" heroTitle="Fairness" title="Provably Fair" close="true" exit={-1} />} >
                    <Route path='/fairness/autoplace' element={<AutoPlace />} />
                    <Route path='/fairness/order' element={<Order />} />


                  </Route>
                </Route>


              </>
              :
              <>
                <Route path='/legal' element={<ModalController type="legal" heroTitle="Legal" title="Legal Disclaimer" close="false" />} replace />
                <Route path='*' element={<Navigate to="/legal" replace />} />

              </>


            :
            <>
              <Route path='/' element={<MainEntry />} />
              <Route path='*' element={<Navigate to="/" replace />} />
            </>
          }
        </Routes>
      }
    </>
  );
}

export default App;
