import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import SubNavBar from '../../subNavbar';
import { alterNotification } from '../../../store/schemas/notificationSlice';
import ProgressMap from '../../progressMap';
const GameActivity = () => {

    const [agreeStatus, setAgreeStatus] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const gameData = useSelector((state) => state.mainapp.gameProgressSlice.gameProgressSlice.pastPlays)

    /*   const gameData = [
          {
              "day": 1,
              "results": [
                  {
                      "username": "mistakensamba#Cfe29",
                      "attack": "A3",
                      "status": [
                          "billbybob6526",
                          "dailyairship",
                          
                      ]
                  },
                  {
                      "username": "dailyairship#116a0",
                      "attack": "A2",
                      "status": "miss"
                  },
                  {
                      "username": "blue-and-whitebutler#93695",
                      "attack": "A1",
                      "status": "miss"
                  },
                  {
                      "username": "lawlessrut#4fadd",
                      "attack": "A9",
                      "status": "miss"
                  },
                  {
                      "username": "mass-producedvulnerability#8D8F6",
                      "attack": "C3",
                      "status": "miss"
                  },
                  {
                      "username": "integratedcontamination#f9B49",
                      "attack": "B3",
                      "status": "miss"
                  }
              ]
          },
          {
              "day": 2,
              "results": [
                  {
                      "username": "mistakensamba#Cfe29",
                      "attack": "B8",
                      "status": "miss"
                  },
                  {
                      "username": "dailyairship#116a0",
                      "attack": "A5",
                      "status": "miss"
                  },
                  {
                      "username": "blue-and-whitebutler#93695",
                      "attack": "B2",
                      "status": "miss"
                  },
                  {
                      "username": "lawlessrut#4fadd",
                      "attack": "B8",
                      "status": "miss"
                  },
                  {
                      "username": "mass-producedvulnerability#8D8F6",
                      "attack": "B2",
                      "status": "miss"
                  },
                  {
                      "username": "integratedcontamination#f9B49",
                      "attack": "C3",
                      "status": "miss"
                  }
              ]
          },
          {
              "day": 3,
              "results": [
                  {
                      "username": "mistakensamba#Cfe29",
                      "attack": "B1",
                      "status": "miss"
                  },
                  {
                      "username": "dailyairship#116a0",
                      "attack": "C6",
                      "status": "miss"
                  },
                  {
                      "username": "blue-and-whitebutler#93695",
                      "attack": "C3",
                      "status": "miss"
                  },
                  {
                      "username": "lawlessrut#4fadd",
                      "attack": "C7",
                      "status": "miss"
                  },
                  {
                      "username": "mass-producedvulnerability#8D8F6",
                      "attack": "A6",
                      "status": "miss"
                  },
                  {
                      "username": "integratedcontamination#f9B49",
                      "attack": "C6",
                      "status": "miss"
                  }
              ]
          },
          {
              "day": 4,
              "results": [
                  {
                      "username": "mistakensamba#Cfe29",
                      "attack": "B5",
                      "status": "miss"
                  },
                  {
                      "username": "dailyairship#116a0",
                      "attack": "A8",
                      "status": "miss"
                  },
                  {
                      "username": "blue-and-whitebutler#93695",
                      "attack": "A3",
                      "status": "miss"
                  },
                  {
                      "username": "lawlessrut#4fadd",
                      "attack": "B6",
                      "status": "miss"
                  },
                  {
                      "username": "mass-producedvulnerability#8D8F6",
                      "attack": "A7",
                      "status": "miss"
                  },
                  {
                      "username": "integratedcontamination#f9B49",
                      "attack": "A4",
                      "status": "miss"
                  }
              ]
          },
          {
              "day": 5,
              "results": [
                  {
                      "username": "mistakensamba#Cfe29",
                      "attack": "C1",
                      "status": "miss"
                  },
                  {
                      "username": "dailyairship#116a0",
                      "attack": "B1",
                      "status": "miss"
                  },
                  {
                      "username": "blue-and-whitebutler#93695",
                      "attack": "B4",
                      "status": "miss"
                  },
                  {
                      "username": "lawlessrut#4fadd",
                      "attack": "A5",
                      "status": "miss"
                  },
                  {
                      "username": "mass-producedvulnerability#8D8F6",
                      "attack": "B3",
                      "status": "miss"
                  },
                  {
                      "username": "integratedcontamination#f9B49",
                      "attack": "C2",
                      "status": "miss"
                  }
              ]
          },
          {
              "day": 6,
              "results": [
                  {
                      "username": "integratedcontamination#f9B49",
                      "attack": "B2",
                      "status": "miss"
                  },
                  {
                      "username": "mass-producedvulnerability#8D8F6",
                      "attack": "A1",
                      "status": "miss"
                  },
                  {
                      "username": "lawlessrut#4fadd",
                      "attack": "B4",
                      "status": "miss"
                  },
                  {
                      "username": "mistakensamba#Cfe29",
                      "attack": "B2",
                      "status": "miss"
                  },
                  {
                      "username": "blue-and-whitebutler#93695",
                      "attack": "C5",
                      "status": "miss"
                  }
              ]
          },
          {
              "day": 7,
              "results": [
                  {
                      "username": "integratedcontamination#f9B49",
                      "attack": "A8",
                      "status": "miss"
                  },
                  {
                      "username": "mass-producedvulnerability#8D8F6",
                      "attack": "A3",
                      "status": "miss"
                  },
                  {
                      "username": "lawlessrut#4fadd",
                      "attack": "C3",
                      "status": "miss"
                  },
                  {
                      "username": "mistakensamba#Cfe29",
                      "attack": "A1",
                      "status": "miss"
                  },
              ]
          }
      ] */

    return (
        <>

            <div className='progress-container vivify fadeIn duration-300'>

                <div className='left-gameBlock'>
                    <h4>Battle Timeline</h4>

                    <div className='battle-timeline'>
                        <div className='b-timeline'>
                            <div className='b-timeline-header'>
                                <h5>Attacker</h5>
                                <h5>Opponent</h5>
                            </div>

                            <div className='b-timeline-body'>

                                {gameData.map((day) => {
                                    return (
                                        <div className='day-grouper'>

                                            <h5>Day {day.day}</h5>
                                            {day.results.map((shot) => {
                                                return (
                                                    <div className='b-time-strip'>
                                                        <div className='b-time-left b-time'>
                                                            <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + shot.username}></img>
                                                            <div className='b-text'>
                                                                <h6>{shot.username}</h6>
                                                            </div>
                                                        </div>
                                                        <div className='b-time-divider'></div>
                                                        {(shot.status != "miss") ?
                                                            <div className='b-time-right b-time'>


                                                                {


                                                                    shot.status != "miss" ?



                                                                        shot.status.length > 1 ?

                                                                            <div className='img-grid'>

                                                                                {
                                                                                    shot.status.length < 4 ?
                                                                                        shot.status.map((status) => {
                                                                                            return (
                                                                                                <div className='img-grid-item'>
                                                                                                    <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + status}></img>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        )
                                                                                        :
                                                                                        <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + shot.status[0]}></img>
                                                                                }
                                                                                {

                                                                                }
                                                                            </div>
                                                                            :
                                                                            <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + shot.status[0]}></img> : null

                                                                }


                                                                <div className='b-text'>
                                                                    {


                                                                        (shot.status.length > 1) ?

                                                                            <details >
                                                                                <summary className='b-text'>
                                                                                    <p><span onClick={
                                                                                        () => {




                                                                                        }
                                                                                    }>{shot.status.length} Player(s)</span></p>
                                                                                </summary>

                                                                                {shot.status.map((status) => {
                                                                                    return (
                                                                                        <div className='b-text vivify fadeIn duration-300' style={
                                                                                            {
                                                                                                marginBottom: '3px'
                                                                                            }
                                                                                        }>
                                                                                            <p>{status}</p>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                }

                                                                            </details>




                                                                            :
                                                                            <h6 style={
                                                                                {
                                                                                    fontSize: '13px'
                                                                                }
                                                                            }>{shot.status[0]}</h6>


                                                                    }

                                                                    <p className='eliminated'>Eliminated</p>
                                                                </div>
                                                                <span>{shot.attack}</span>
                                                            </div>
                                                            :
                                                            <div className='b-time-right b-time'>
                                                                <img src={'https://th.bing.com/th/id/OIP.XMCnkvAWeStGCMOkkGliLQHaHa?pid=ImgDet&rs=1'}></img>
                                                                <div className='b-text'>
                                                                    <h6>Missed</h6>
                                                                    <p>Empty Location</p>
                                                                </div>

                                                                <span>{shot.attack}</span>
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            })}




                                        </div>

                                    )
                                })}












                            </div>
                        </div>
                    </div>


                </div>

                <div style={
                    {
                        display: 'none',
                    }
                } className='right-gameBlock'>
                    <h4>Game Stats</h4>
                    <ProgressMap/>

                </div>

            </div>



        </>
    );

}

export default GameActivity;