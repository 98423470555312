import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import skull from '../../../assets/skull.svg';
import { alterGameProgress } from '../../../store/schemas/gameProgressSlice';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';

import SubNavBar from '../../subNavbar';
const GameModal = () => {
  const progress = useSelector(state => state.mainapp.gameProgressSlice.gameProgressSlice)
  const [enemyTeamLength, setEnemyTeamLength] = useState(3);
  const [userTeamLength, setUserTeamLength] = useState(3);
    const navigate = useNavigate()

    return (
        <>

<SubNavBar links={
        [{
          'name': 'Report',
          'link': '/games/progress/report'
        },
        {
          'name': 'Game Activity',
          'link': '/games/progress/activity'
        }
        ]
      }
      />
        <Outlet />

           <style>
        {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}

        .sub-navbar-wrapper{
            position: static;
        }
        `}
      </style>
        </>
    );


}

export default GameModal;