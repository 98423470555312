import React, {useRef, useEffect, useState} from 'react';
import SideToolBar from '../../../components/sideToolbar';
import Footer from '../../../components/footer';
const ShopMenu = () => {

  return (
    <>
   
     <div className='menu-body vivify fadeIn duration-300 delay-200'>
    
     <h1 className='page-title'>SHOP</h1>
     
        <SideToolBar 
        links={
        [{
          'name': 'Featured',
          'id': 'featured'
        }
     
        ]
      }
      defaultLink={'featured'}
      />


     </div>
   
    </>
  );

}

export default ShopMenu;