import React, { useRef, useEffect, useState } from 'react';
import BackPlate from '../../components/backplate';
import { trackTransaction, updateProdToken } from '../../httpcalls/moneyThunk';
import Web3 from 'web3';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader';
import { alterNotification } from '../../store/schemas/notificationSlice';
import { validateUserInput } from '../../httpcalls/gameThunk';
import { useNavigate } from 'react-router-dom';
import { loadingReset, setLoadingSlice } from '../../store/schemas/loadingSlice';
import { createContract } from '../../helper-functions/contract';
const MatchMaking = (prop) => {
  const dispatch = useDispatch()
  
  const userInfo = useSelector((state) => state.auth.userInfo)

  const defendPoint = useSelector(state => state.mainapp.gameSlice.gameManager.defend)
  const navigate = useNavigate()
  const busdContract = async () => {
    try {
      const contract = await createContract(10, true)
      if("error" in contract ){
          throw contract
      }
      dispatch(setLoadingSlice('Sending and verifying payments...'))
      dispatch(trackTransaction({tx:contract.tx})).then((res) => {
        dispatch(loadingReset())
  
        if(!("error" in res.payload)){
          dispatch(alterNotification( {autoClose: 10000, alertStyle:  'success',  messageTitle: 'Purchase Success', message: 'Your purchased succeeded.', visible: true} )) ;
          navigate('/games', {replace:true})
        }
     })
      
    } catch (error) {
      dispatch(loadingReset())
      dispatch(alterNotification( {autoClose: 10000, alertStyle:  'warning',  messageTitle: error.error, message: error.message, visible: true} )) ;
    }
}

const runPayment = async () => {
   busdContract()

}


useEffect(() => {
  dispatch(alterNotification( {autoClose: 10000, alertStyle:  'success',  messageTitle: 'Your War Plan Has Been Confirmed', message: 'Your ready to begin match making. ', visible: true} )) ;
}, []);
   
  return (
    <>
      

     <div className='game-overview-container'>
        
      <div>
      <BackPlate backplateType="entry" />
      <div className='back-title'>
          <h1 style={{ marginLeft: "-15px" }}>Game</h1>
        </div>
        <div className='game-desc'>
          <p>PROVABLY RANDOM</p>
          <h1 className='page-title' style={{ marginTop: "0px" }}>MATCH MAKING</h1>
          <span>After payment we’ll match you with a team and the game will begin. </span>
        </div>
      </div>

      <div className='game-overview'>
      <div className='over-card'>
            <h2>Game Details</h2>
            <div className='over-card-content'>
                <div className='split-card'>
                    <div className='split-left'>
                        <div className='circle-date'>
                            <h2>28</h2>
                            <h3>Days</h3>
                        </div>
                    </div>
                    <div className='split-right'>
                        <div>
                        <h3>Info</h3>
                        <p>Check back every 24 hours for changes to the game. The more kills you get the larger your payout. </p>
                        </div>
                    
    <div><h3>YOUR DEFENSE</h3>
                        <p>Your defense point is {defendPoint}</p></div>
                        
                        </div>
                </div>
                </div>
        </div>

        <div className='over-card'>
            <h2>Payment</h2>
            <div className='over-card-content'>
                <div className='center-card'>
                    <div className='center-text'>
                    <h3>BUSD</h3>
                    <h2>10.00</h2>
                    <p>$10.00 USD</p>
                    </div>
                    <p>Note: A portion of your entry fee will be put into a kill rewards pool.</p>
                    
                </div>
                </div>
      </div>

      <div className='over-card-footer'>
        <div className='massive-button-container'>
        <button  onClick={() => {runPayment()}} className='massive-button'> <i className='material-icons'>
            radar
            </i>
            START MATCHMAKING & PAY  
            </button>
        </div>
           
            <p>By Clicking "Start Match Making & Pay" You Agree To Our <button className=''>Disclaimer</button></p>
      </div>
       
      </div>
      </div>
 

 <div className='notification-spacer'></div>
      

     <style>
        {`
       :root {
        --main-color: var(--alt-color)
       }

       .gamebar-container {
        width: 60%
       }

       .page-title {
        position: static
       }

       .play-zone {
        display:none
       }
        `}
      </style>
    </>
  );

}

export default MatchMaking;