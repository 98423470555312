import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import BackTitle from './backTitle';
const SubNavBar = (props) => {
    const [currentPage, setCurrentPage] = useState('');
    const links = props.links;




    return (
        <>
            <div className='sub-navbar-wrapper vivify fadeIn duration-300 delay-100'>
                <div className='sub-navbar'>

                    {links.map((link) => (
                        <NavLink to={link.link} replace={true} className={ (window.location.pathname == link.link) ? "focused-sub" : "inactive-sub"}>
                            <div className='sub-navbar-item '>
                                <p>{link.name}</p>
                            </div>
                        </NavLink>
                    ))}




                </div>

                <BackTitle title={currentPage} />
            </div>

        </>
    );

}

export default SubNavBar;