import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from "react-router-dom";
import quickplayimg from '../../../assets/homBackground.png';
import MyGameCard from '../../../components/myGameCard';
import { getUsersLobbiesInfo } from '../../../httpcalls/gameThunk';
import { v4 as uuidv4 } from 'uuid';
import { getUser } from '../../../httpcalls/authThunk';
import WaitingCard from '../../../components/cards/waitingCard';
import LobbyNoMinCard from '../../../components/cards/lobbyNoMinCard';
import LobbyActiveGameCard from '../../../components/cards/lobbyActiveGameCard';
import LobbyFinishedCard from '../../../components/cards/lobbyFinishedCard';


const M_AllGames = (props) => {
    const dispatch = useDispatch()
    const [activeGames, setActiveGames] = useState(null)
    const userGames = useSelector(state => state.auth.userInfo.lobbies)
    const location = useLocation()

    const vis = (e) => {
        if (document.visibilityState === "visible") {
            dispatch(getUser())
            dispatch(getUsersLobbiesInfo()).then((res) => {
                setActiveGames(res.payload.message)
            })
        } 
    }
    useEffect(() => {
        dispatch(getUser())
        document.addEventListener("visibilitychange", vis)
        return () => {document.removeEventListener('visibilitychange', vis)};
    },[])
    useEffect(() => {
        if(window.location.pathname == "/games"){
            dispatch(getUsersLobbiesInfo()).then((res) => {
                setActiveGames(res.payload.message)
            })
        }
        else{
            //COMMENT ME OUT TO NOT DERENDER COMPONENTS
            // setActiveGames(null)
        }
    },[location])




    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
         <div className='side-content' style={{flexDirection: "column", justifyContent: "flex-start", gap: "40px"}}>

         <div style={
            {
                display: 'none'
            }
         } className='block-layout'>
          <div className='hero-block' style={
            {
              backgroundImage: `url(${quickplayimg})`
            }
          }>
            <div className='block-tagline'>
              <div className='block-vert-divder'>
                <div className='block-vert-line'></div>
              </div>
              <h1>my games</h1>
            </div>
            <div className='block-charm'>
              <div className='charm-text'>
               
                <h2>My <span>Games</span> </h2>
                <p>Join the battle for rewards, fight up to 27 players for 28 days and claim your prize!</p>
              </div>
              <div className='charm-button'>
                <button className='btn btn-primary' onClick={
                  () => {
                   alert('Coming Soon');


                  }
                }>Play Now</button>
                <button onClick={
                  () => {
                    localStorage.setItem('agreeStatus', 'false');
                    window.location.reload();
                  }
                }>
                  Disclaimer
                </button>
              </div>
            </div>
          </div>
       
        </div>

           

            <div className='mygames-container vivify fadeIn duration-300 delay-100'>
                <div className='mygames-wrapper vivify fadeIn duration-300 delay-100'>
                    {
                        (activeGames != null) ? 
                            activeGames.length == 0 || userGames.length == 0 ?
                                <div className='game-card-header' style={{"display":"flex","flexDirection":"column","margin":"auto","padding":"4rem","lineHeight":"3.4rem","paddingBottom":"2rem"}}>
                                    <h1>It's pretty empty in here...</h1>
                                    <p>Why not go start a game, it'll make this screen more lively!</p>
                                </div>
                               
                            :

                            (() => {
                                let filterLobbies = []
                                if(props.type == 'complete'){
                                    filterLobbies = userGames.filter((lobby) => (activeGames.find(active => active.id == lobby)).active_view == false)
                                }
                                else if(props.type == 'active'){
                                    filterLobbies = userGames.filter((lobby) => (activeGames.find(active => active.id == lobby)).active_view == true)
                                }
                                else{
                                    filterLobbies = userGames
                                }

                                if(filterLobbies.length == 0){
                                    if(props.type == 'complete'){
                                        return (
                                            <div className='game-card-header' style={{"display":"flex","flexDirection":"column","margin":"auto","padding":"4rem","lineHeight":"3.4rem","paddingBottom":"2rem"}}>
                                                <h1>No completed games detected</h1>
                                                <p>Finish a game to fill this area!</p>
                                            </div>
                                        )
                                    }
                                    else{
                                        return (
                                            <div className='game-card-header' style={{"display":"flex","flexDirection":"column","margin":"auto","padding":"4rem","lineHeight":"3.4rem","paddingBottom":"2rem"}}>
                                                <h1>No active games detected</h1>
                                                <p>Why not go start a game, it'll make this screen more lively!</p>
                                            </div>
                                        )
                                    }
                                  
                                }
                               return filterLobbies.map((item, index) => {
                                    const getGame = activeGames.find((game) => game.id == item)
                                    console.log(getGame)
                                    if(getGame.lock_timer == null){
                                        return (
                                            <LobbyNoMinCard key={uuidv4()} day={getGame.day} trackNum={getGame.id}  players={getGame.player_count}/>
                                        )
                                    }
                                    else {
                                        
                                        const user_time = new Date(new Date().toISOString())
                                        const user_time_total_seconds = user_time.getHours() * 60 *60 + user_time.getMinutes() * 60 + user_time.getSeconds()
                                        const server_time = new Date(new Date(getGame.lock_timer).toISOString())
                                        const server_time_total_seconds = server_time.getHours() * 60 * 60 + server_time.getMinutes() * 60 + server_time.getSeconds()
                                        let timeDifference = 0
                                        if((user_time_total_seconds - server_time_total_seconds) < 0){
                                                timeDifference = (server_time_total_seconds - user_time_total_seconds)
                                        }
                                        else{
                                                timeDifference = (24 * 60 * 60) - (user_time_total_seconds - server_time_total_seconds)
                                        }
                                        if(getGame.active_view == true){
                                            return(
                                                <LobbyActiveGameCard key={uuidv4()} value={getGame.busd.toLocaleString("en-US", { style: 'currency', currency: 'USD' })} day={getGame.day} trackNum={getGame.id} status="taken" players={getGame.player_count} time={timeDifference} />
                                            )
                                        }
                                        else{
                                            console.log(getGame)
                                            return(
                                                <LobbyFinishedCard dead={getGame.dead}  claim={getGame.claim} key={uuidv4()} busd={getGame.busd} value={getGame.busd.toLocaleString("en-US", { style: 'currency', currency: 'USD' })} players={getGame.player_count} day={getGame.day} trackNum={getGame.id} status="claim"  />
                                            )
                                        }
                                    }

                                })

                                })()
                           
                                // else{
                                //     const gameInfo = activeGames.find(game => game.id.toString() == item)
                                //     if(gameInfo != undefined){
                                //         console.log(gameInfo)
                                //         if(gameInfo.lock_timer == null){
                                //             return(
                                //                 <MyGameCard key={uuidv4()} status="waiting" />
                                //             )
                                //         }
                                //         else{
                                //             if(gameInfo.dead == true){
                                //                 return (
                                //                 <MyGameCard key={uuidv4()} busd={gameInfo.busd} value={gameInfo.busd.toLocaleString("en-US", { style: 'currency', currency: 'USD' })} players={gameInfo.player_count} day={gameInfo.day} trackNum={index + 1} status="claim" />
                                //                 )
                                //             }
                                            
                                //             const user_time = new Date(new Date().toISOString())
                                //             const user_time_total_seconds = user_time.getHours() * 60 *60 + user_time.getMinutes() * 60 + user_time.getSeconds()
                                //             const server_time = new Date(new Date(gameInfo.lock_timer).toISOString())
                                //             const server_time_total_seconds = server_time.getHours() * 60 * 60 + server_time.getMinutes() * 60 + server_time.getSeconds()
                                //             let timeDifference = 0
                                //             if((user_time_total_seconds - server_time_total_seconds) < 0){
                                //                  timeDifference = (server_time_total_seconds - user_time_total_seconds)
                                //             }
                                //             else{
                                //                  timeDifference = (24 * 60 * 60) - (user_time_total_seconds - server_time_total_seconds)
                                //             }

                                //             return (
                                //                 <MyGameCard key={uuidv4()} value={gameInfo.busd.toLocaleString("en-US", { style: 'currency', currency: 'USD' })} day={gameInfo.day} trackNum={index + 1} status="taken" players={gameInfo.player_count} time={timeDifference}/> 
                                //             )
                                //         }
                                //     }
                                // }
                                
                        :

                             <div className='loader vivify swoopInBottom delay-100'>
            <div className='loader-inner '>
            <img src='https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif'></img>
                <h3 className='vivify'>Loading Games...</h3>

                </div>
            </div>
                        // activeGames.map((item) => {
                        //     if(item.)
                        //     return (
                        //         <MyGameCard status="waiting" time={400 * 30} />
                        //     )
                        // })
                    }
                    {/* <MyGameCard status="waiting" time={400 * 30} /> */}
                    {/* <MyGameCard value="148.00" day="21" trackNum="1" status="claim" />
                    <MyGameCard value="148.00" day="21" trackNum="2" status="available"  /> */}
                    {/* <MyGameCard value="148.00" day="21" trackNum="3" status="available" />
                    <MyGameCard value="148.00" day="21" trackNum="4" status="available" />
                    <MyGameCard value="148.00" day="21" trackNum="5" status="taken" time={0.3 * 30}/>
                    <MyGameCard value="148.00" day="21" trackNum="6" status="taken" time={19 * 60}/>
                    <MyGameCard value="148.00" day="21" trackNum="7" status="taken" time={20 * 50}/>
                    <MyGameCard value="148.00" day="21" trackNum="8" status="taken" time={21 * 10}/>
                    <MyGameCard value="148.00" day="21" trackNum="9" status="taken" time={20 * 30}/> 

                                        <MyGameCard value="148.00" day="21" trackNum="7" status="taken" time={20 * 50}/> */}

                
                    </div>
            </div>

            </div>
          
        </>
    );

}

export default M_AllGames;